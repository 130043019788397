import React, { useContext } from 'react'
import { FormContext } from '../../FormContext';
const Select = ({ field_id, field_label, field_placeholder, field_value, field_options }) => {
    const { handleChange } = useContext(FormContext)
    //console.log("select valeues",field_id, field_label, field_placeholder, field_value, field_options);
    return (
        <div className="mb-3">
            <label className="form-label">{field_label}</label>
            <select value={field_value} className="form-select" aria-label="Default select example"
                onChange={event => handleChange(field_id, event)}
            >
                {/* add default select */}
                {field_options.length > 0 && field_options.map((option, i) =>
                    {return <option value={option.option_label} key={i}>{option.option_label}</option>}
                )}
            </select>
        </div>
    )
}

export default Select
