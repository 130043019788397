import { useState, useEffect, useMemo, useRef } from "react";
import "./App.css";
import React from "react";
import Element from "./components/Element";
import { FormContext } from "./FormContext";
import axios from "axios";

import awsconfig from "./aws-exports";
// import 'bootstrap/dist/css/bootstrap.min.css';
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";

import Amplify from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import { useLocation } from "react-router-dom";

Amplify.configure(awsconfig);

const NOTSIGNIN = "You are NOT logged in";
const SIGNEDIN = "";
const SIGNEDOUT = "You have logged out successfully";
const WAITINGFOROTP = "Enter OTP number";
const VERIFYNUMBER = "Sending OTP";
const USERNOTEXIST = "Sorry! User does not exist";
const INVALIDOTP = "Invalid OTP Entered ! Please generate OTP again";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function App() {
  const [message, setMessage] = useState(null);
  const [user, setUser] = useState(localStorage.getItem("user"));
  const [session, setSession] = useState(localStorage.getItem("session"));
  const [otp, setOtp] = useState("");
  const [number, setNumber] = useState("");
  const [button_loader, setButton_loader] = useState("nothing");
  const [button_loader1, setButton_loader1] = useState("nothing");
  const [button_loader2, setButton_loader2] = useState("nothing");
  const [button_loader3, setButton_loader3] = useState("nothing");
  const password = Math.random().toString(10) + "Abc#";

  let query = useQuery();

  const key = query.get("key");
  const key2 = query.get("key2");
  const key3 = query.get("key3");
  const key4 = query.get("key4");
  const key5 = query.get("key5");

  // key=new
  // key2=simarjit%40floworks.in
  // key3 = ABC123_2
  // key4 = 1ttoCXSq8eCG3NkrI2bSNt8vBF5peVinU933gaoWYqFM
  // key5 = Floworks + Deal
  useEffect(() => {
    verifyAuth();
  }, []);

  const verifyAuth = () => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
        setMessage(SIGNEDIN);
      })
      .catch((err) => {
        console.error(err);
        setMessage(NOTSIGNIN);
      });
  };
  const signOut = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("session");
    if (user) {
      Auth.signOut();
      setUser(null);
      setOtp("");
      setMessage(SIGNEDOUT);
      setSession(null);
    } else {
      setSession(null);
      setMessage(NOTSIGNIN);
    }
  };
  const signIn = () => {
    var number1 = number.replace("+", "");
    //console.log(number1);
    axios({
      method: "POST",
      url: "https://whatsapp.dev.floworks.ai/whatsapp/user/get",
      data: {
        phoneNum: number1,
      },
    })
      .then((res) => {
        setMessage(VERIFYNUMBER);
        signUp();
      })
      .catch((e) => {
        if (e.message === "Request failed with status code 500") {
          setMessage(USERNOTEXIST);
        }
      });
  };
  const signUp = async () => {
    setButton_loader1("load");
    await Auth.signUp({
      username: number,
      password,
      attributes: {
        phone_number: number,
      },
    })
      .then(() =>
        Auth.signIn(number).then((result) => {
          setSession(result);
          setMessage(WAITINGFOROTP);
          setButton_loader1("nothing");
        })
      )
      .catch((e) => {
        //console.log("err",e);
        if (e.code === "UsernameExistsException") {
          Auth.signIn(number)
            .then((result) => {
              setSession(result);
              setMessage(WAITINGFOROTP);
              setButton_loader1("nothing");
            })
            .catch((e) => {
              //console.log('err1',e)
            });
        } else {
          //console.log(e.code);
          setButton_loader1("nothing");
          console.error(e);
        }
      });
  };
  const verifyOtp = () => {
    //console.log('session login',session,otp);
    setButton_loader2("load");
    Auth.sendCustomChallengeAnswer(session, otp)
      .then((user) => {
        setUser(user);
        setMessage(SIGNEDIN);
        localStorage.setItem("user", user);
        localStorage.setItem("session", session);
        setButton_loader2("nothing");
        // setSession(null);
      })
      .catch((err) => {
        setMessage(INVALIDOTP);
        setSession(null);
        setUser(null);
        setOtp("");
        setButton_loader2("nothing");
        //console.log(err);
      });
  };

  const [elements, setElements] = useState(null);
  // var var1 = window.location.pathname;
  // var var2 = var1.split("/")[1].split("&");
  // var key2 = queries.key2;
  // var key3 = queries.key3;
  const [sheetid, setSheetid] = useState("");
  const [sheetname, setSheetname] = useState("");
  const [idcol, setIdcol] = useState("");
  // for (var i = 0; i < var2.length; i++) {
  //   //console.log(var2[i])
  //   if (var2[i].startsWith("key2")) {
  //     key2 = var2[i].split("=")[1];
  //   }
  //   if (var2[i].startsWith("key3")) {
  //     key3 = var2[i].split("=")[1];
  //   }
  // }
  //console.log(key2,key3);

  useMemo(() => {
    setButton_loader3("load1");
    verifyAuth();
    axios({
      method: "POST",
      url: "https://googlesheet.dev.floworks.ai/sheet/getDefaultSpreadsheetId",
      data: {
        WorkspaceId: key2,
        Connector: "sheets",
      },
    })
      .then((res) => {
        //console.log(res.data);
        setSheetid(res.data.response["spreadsheetId"]);
        setSheetname(res.data.response["sheet"]);
        setIdcol(res.data.response["idColumn"]);
        const obj = {
          method: "POST",
          url: "https://googlesheet.dev.floworks.ai/sheet/getDataById",
          data: {
            userCred: {
              WorkspaceId: key2,
              Connector: "sheets",
            },
            // "spreadsheetId": "1nujPy90IOBJL412yuHrRjJFsGsqtBbuFAvwaba5LRg0",
            // "spreadsheetId": "17sr8JM3NUfF6_ZSlwOdIRwpKZf-z5PBeS6f6cgACPTk",
            spreadsheetId: res.data.response["spreadsheetId"],
            sheet: res.data.response["sheet"],
            idColumn: res.data.response["idColumn"],
            customerId: key3 || "",
            newFlag: "false",
          },
        };
        if (key && key === "new") {
          obj["data"]["newFlag"] = "true";
        }
        axios(obj)
          .then((res) => {
            //console.log('api_return',res)
            var dict3 = {};
            dict3["page_label"] = "Response";
            var l1 = [];
            for (var i = 0; i < res.data.response.values.length; i++) {
              var var1 = res.data.response.values[i];
              if (var1.dataValidation !== {}) {
                var var2 = var1.dataValidation.condition;
                var field_ops_temp = null;
                for (var key in var2) {
                  if (key === "type" && var2[key] === "ONE_OF_LIST") {
                    field_ops_temp = var2.values;
                    break;
                  }
                }
                if (field_ops_temp !== null) {
                  // //console.log(field_ops_temp);
                  var dictx = {};
                  dictx = {};
                  dictx["field_options"] = [];
                  for (var temp1 = 0; temp1 < field_ops_temp.length; temp1++) {
                    dictx["field_options"].push({
                      option_label: field_ops_temp[temp1].userEnteredValue,
                    });
                  }
                  dictx["field_id"] = (Math.random() + 1)
                    .toString(36)
                    .substring(7);
                  dictx["field_value"] =
                    var1.formattedValue !== "" ? var1.formattedValue : " ";
                  dictx["field_type"] = "select";
                  dictx["field_title"] = var1.title;
                  dictx["field_label"] = var1.title;
                  l1.push(dictx);
                  continue;
                }
              }
              if (Object.keys(var1.effectiveFormat).length !== 0) {
                //console.log(var1.effectiveFormat)
                var2 = var1.effectiveFormat;
                var date_present = false;
                var date_format = "";
                var time_present = false;
                var time_format = "";
                var id_present = false;
                if ("type" in var2 && var2["type"] === "DATE") {
                  date_present = true;
                  date_format = var2["pattern"];
                  //console.log(date_present,date_format);
                }
                if ("type" in var2 && var2["type"] === "TIME") {
                  time_present = true;
                  time_format = var2["pattern"];
                  //console.log(time_present,time_format);
                }
                if ("type" in var2 && var2["type"] === "TEXT") {
                  id_present = true;
                }

                dictx = {};
                if (date_present) {
                  dictx = {};
                  dictx["field_options"] = date_format;
                  dictx["field_id"] = (Math.random() + 1)
                    .toString(36)
                    .substring(7);
                  dictx["field_value"] =
                    var1.formattedValue !== "" ? var1.formattedValue : " ";
                  //console.log(dictx["field_value"]);
                  dictx["field_type"] = "DATE";
                  dictx["field_title"] = var1.title;
                  dictx["field_label"] = var1.title;
                  l1.push(dictx);
                }
                if (time_present) {
                  dictx = {};
                  dictx["field_options"] = time_format;
                  dictx["field_id"] = (Math.random() + 1)
                    .toString(36)
                    .substring(7);
                  dictx["field_value"] =
                    var1.formattedValue !== "" ? var1.formattedValue : " ";
                  //console.log(dictx["field_value"]);
                  dictx["field_type"] = "TIME";
                  dictx["field_title"] = var1.title;
                  dictx["field_label"] = var1.title;
                  l1.push(dictx);
                }
                if (id_present) {
                  dictx = {};
                  dictx["field_id"] = (Math.random() + 1)
                    .toString(36)
                    .substring(7);
                  dictx["field_value"] =
                    var1.formattedValue !== "" ? var1.formattedValue : " ";
                  dictx["field_type"] = "string";
                  dictx["field_title"] = var1.title;
                  dictx["field_label"] = var1.title;
                  l1.push(dictx);
                }

                //console.log(dictx);
                continue;
              }
              dictx = {};
              dictx = {};
              dictx["field_id"] = (Math.random() + 1).toString(36).substring(7);
              dictx["field_value"] =
                var1.formattedValue !== "" ? var1.formattedValue : " ";
              dictx["field_type"] = "string";
              dictx["field_title"] = var1.title;
              dictx["field_label"] = var1.title;
              dictx["field_options"] = "";
              l1.push(dictx);
            }

            dict3["fields"] = l1;
            //console.log('initial values',dict3);
            setElements(dict3);
            setButton_loader3("nothing");
            // setElements(formJSON[0]);
          })
          .catch((err) => {
            //console.log(err);
          });
      })
      .catch((err) => {
        //console.log(err);
      });
  }, []);

  const { fields } = elements ?? {};
  const handleSubmit = (event, fields) => {
    setButton_loader("load");
    event.preventDefault();
    //console.log(fields)
    var final_data = {};
    for (var temp1 = 0; temp1 < fields.length; temp1++) {
      final_data[fields[temp1]["field_title"]] = {
        format: fields[temp1]["field_options"],
        type: fields[temp1]["field_type"],
        value: fields[temp1]["field_value"],
      };
    }
    //console.log("spreadsheetId-",sheetid," sheet-",sheetname," idColumn-",idcol," customerId-","1")
    axios({
      method: "POST",
      url: "https://googlesheet.dev.floworks.ai/sheet/updateValuesById",
      data: {
        userCred: {
          WorkspaceId: key2,
          Connector: "sheets",
        },
        spreadsheetId: sheetid,
        sheet: sheetname,
        idColumn: idcol,
        customerId: key === "new" ? "" : key3,
        values: final_data,
        newFlag: key === "new" ? "true" : "false",
      },
    })
      .then((res) => {
        setButton_loader("checkmark");
        setTimeout(function () {
          setButton_loader("nothing");
        }, 1000);
      })
      .catch((err) => {
        setButton_loader("nothing");
      });

    // //console.log(final_data)
    // //console.log(elements)
  };
  const handleChange = (id, event) => {
    const newElements = { ...elements };
    newElements.fields.forEach((field) => {
      const { field_type, field_id } = field;
      if (id === field_id) {
        switch (field_type) {
          case "checkbox":
            field["field_value"] = event.target.checked;
            break;
          case "DATE":
            field["field_value"] = event;
            break;
          case "TIME":
            field["field_value"] = event;
            break;
          case "select":
            field["field_value"] =
              event.target[event.target.selectedIndex].text;
            break;
          default:
            field["field_value"] = event.target.value;
            break;
        }
      }
      setElements(newElements);
    });
    // //console.log(elements)
  };

  return (
    <div className="App">
      <header className="App-header">
        <p>{message}</p>
        {!user && !session && (
          <div>
            <InputGroup className="mb-3">
              <FormControl
                className="form-control form-control-number-input"
                placeholder="Enter Phone Number"
                defaultValue={number ? number : "+91"}
                onChange={(event) => setNumber(event.target.value)}
              />
              <Button className="button get-otp" onClick={signIn}>
                <div class={button_loader1}>Get OTP</div>
              </Button>
            </InputGroup>
          </div>
        )}
        {!user && session && (
          <div>
            <InputGroup className="mb-3">
              <FormControl
                className="form-control form-control-number-input"
                placeholder="Your OTP"
                onChange={(event) => setOtp(event.target.value)}
                value={otp}
              />
              <Button className="button get-otp" onClick={verifyOtp}>
                <div class={button_loader2}>Confirm</div>
              </Button>
            </InputGroup>
          </div>
        )}
        {user && session && (
          <div class={button_loader3}>
            <FormContext.Provider value={{ handleChange }}>
              <form className="form-control form-control-text-input">
                {fields
                  ? fields.map((field, i) => <Element key={i} field={field} />)
                  : null}
                {/* <Button variant='outline-danger' type="submit" onClick={(e) => handleSubmit(e,fields)}>Submit</Button> */}
              </form>
            </FormContext.Provider>
            <div className="button-group-1">
              <div class="element">
                <Button
                  className="button button1"
                  type="submit"
                  onClick={(e) => handleSubmit(e, fields)}
                >
                  <div class={button_loader}>Submit</div>
                </Button>
              </div>
              <div class="element">
                <Button
                  className="button button2"
                  variant="outline-danger"
                  onClick={signOut}
                >
                  Sign Out
                </Button>
              </div>
            </div>
          </div>
        )}
      </header>
    </div>
  );
}
export default App;
