import React, { useContext,useState } from 'react'
import { FormContext } from '../../FormContext';
import Datetime from 'react-datetime';
import '../Date.css';
import moment from 'moment';
const Date = ({ field_id, field_label, field_placeholder, field_value, field_options }) => {
    const { handleChange } = useContext(FormContext)
    const [date, setValue] = useState();
    var date1 = moment('31-12-1899','DD-MM-YYYY').add(field_value-1, 'days');
    function handleChangex(e){
        setValue(e._d)
    }
    function changedate(e){
        var var1 = moment(e,'DD-MM-YYYY');
        var var2 = var1.diff(moment('31-12-1899','DD-MM-YYYY'), 'days')+1;
        return var2;
    }

    return (
        <div className="mb-3">
            <label className="form-label">{field_label}</label>
            {/* <input type='date' value={field_value} onChange={e=>setDate(e.target.value)} /> */}
            <Datetime initialValue={date1} setValue={date} timeFormat={false} onChange={e=>{handleChangex(e)}} onClose={e=>{handleChange(field_id,changedate(e))}} closeOnSelect={true} />
        </div>
    )
}

export default Date;
