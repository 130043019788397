import React, { useContext,useState } from 'react'
import { FormContext } from '../../FormContext';
import moment from 'moment';
import Datetime from 'react-datetime';
import '../Date.css';

const Time = ({ field_id, field_label, field_placeholder, field_value, field_options }) => {
    const { handleChange } = useContext(FormContext)
    const [time, setValue] = useState();
    var hour1 = parseInt((field_value*86400)/3600).toString();
    var min1 = parseInt(((field_value*86400)%3600)/60).toString();
    var sec1 = parseInt((((field_value*86400)%3600)%60)).toString();
    function handleChangex(e){
        setValue(e._d)
    }
    function changedate(e){
        var var1 = e.format("HH:mm:ss");
        var hour2 = parseInt(var1.split(':')[0]);
        var min2 = parseInt(var1.split(':')[1]);
        var sec2 = parseInt(var1.split(':')[2]);
        return (hour2*3600+min2*60+sec2)/86400;
    }

    return (
        <div className="mb-3">
            <label className="form-label">{field_label}</label>
            <Datetime initialValue={moment(hour1+':'+min1+':'+sec1,'hh:mm:ss').toDate()} setValue={time} dateFormat={false} onChange={e=>{handleChangex(e)}} onClose={e=>{handleChange(field_id,changedate(e))}} closeOnSelect={true} />
        </div>
    )
}

export default Time;
